import React from 'react';
import Img from 'gatsby-image';

import {TickIcon} from 'assets/icons';

import './icon-list.scss';

const IconList = ({icons}) => {
    return (
        <ul className="icon-list">
            {icons.map((icon, id) => (
                <li key={id} className="icon-list__item">
                    <div className="icon-list__icon">
                        <Img fluid={icon.fluid} alt={`Projekte yra ${icon.title}`} />
                    </div>
                <span className="icon-list__text">{icon.title}</span>
                <div className="icon-list__info">
                    {icon.description ? icon.description : <TickIcon />}
                </div>
            </li>
            ))}
        </ul>
    );
};

export default IconList;
import React, { useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import "./_gallery.scss";

const Gallery = ({ images }) => {
  const [lightbox, setLightbox] = useState(null);

  const handleLightboxOpen = image => {
    document.body.classList.add("menu--open");
    setLightbox(image);
  };

  const handleLightboxClose = () => {
    document.body.classList.remove("menu--open");
    setLightbox(null);
  };

  return (
    <>
      <div className="gallery">
        {images.map((image, id) => (
          <div
            className="gallery__image-wrapper"
            key={id}
            onClick={() => handleLightboxOpen(image.fluid)}
          >
            <Img fluid={image.fluid} className="gallery__image" />
          </div>
        ))}
      </div>
      {lightbox && (
        <div
          className="gallery__lightbox"
          onClick={() => handleLightboxClose()}
        >
          <div className="gallery__lightbox-image-wrapper">
            <Img
              fluid={lightbox}
              className="gallery__lightbox-image"
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

Gallery.propTypes = {};

export default Gallery;

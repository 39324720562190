import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import SEO from "components/SEO";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Heading from "components/Heading";
import Footer from "components/Footer";
import RichTextRenderer from "components/RichTextRenderer";
import Button from "components/Button";
import Gallery from "components/Gallery";
import IconList from "components/IconList";
import CookieBar from "components/CookieBar";

import "./_product.scss";

const Product = props => {
  const { logo } = props.data;
  const {
    title,
    slug,
    cover,
    description,
    plan,
    banner,
    bannerText,
    priceMinimal,
    firstTextColumnMinimal,
    secondTextColumnMinimal,
    thirdTextColumnMinimal,
    gallery,
    icons,
    seoTitle,
    seoDescription,
  } = props.data.contentfulProduct;
  const SEOtitle = seoTitle ? seoTitle : title;

  return (
    <>
      <SEO
        title={SEOtitle}
        description={seoDescription}
        image={cover.file.url}
        pathname={slug}
      />
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai namai logotipas"
      />
      <Banner isProduct imageData={cover.fluid} />
      <Layout>
        <div className="product">
          <div className="product__header">
            <Heading headingLevel="h1">{title}</Heading>
            <span className="product__price">nuo {priceMinimal} €</span>
            <p className="product__description">{description.description} </p>
          </div>
          <div className="product__plan-container">
            <div className="product__plan">
              <Img fluid={plan.fluid} alt={plan.title} />
            </div>
            <div className="product__icons">
              <IconList icons={icons} />
            </div>
          </div>
          {banner && <Img fluid={banner.fluid} alt={banner.title} />}
          {bannerText && (
            <p className="product__text">{bannerText.bannerText}</p>
          )}
          <div className="product__content">
            <div className="product__info">
              <div className="product__text-column">
                <RichTextRenderer richTextJson={firstTextColumnMinimal.json} />
              </div>
              <div className="product__text-column">
                <RichTextRenderer richTextJson={secondTextColumnMinimal.json} />
              </div>
              <div className="product__text-column">
                <RichTextRenderer richTextJson={thirdTextColumnMinimal.json} />
                <div className="product__price-small">
                  nuo {priceMinimal} €
                  <div className="product__price-small-info">
                    Kaina (su PVM)
                  </div>
                </div>
                <div className="product__button-helper">
                  <Button url="/kontaktai" isMain>
                    Rezervuoti
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Gallery images={gallery} />
          <p className="product__text">
            Sudomino ši namelio komplektacija? Norite gauti daugiau informacijos
            apie šį projektą? Susisiekite su mumis ir mes pasistengsime atsakyti
            į visus Jums rūpimus klausimus apie modulinių namų gamybą,
            pasirinktą komplektaciją, pristatymą ir patį procesą.
          </p>
          <div className="product__button-helper">
            <Button url="/kontaktai" isMain>
              Susisiekti
            </Button>
          </div>
        </div>
      </Layout>
      <Footer logo={logo.childImageSharp.fluid} />
      <CookieBar />
    </>
  );
};

export default Product;

export const pageQuery = graphql`
  query Product($slug: String!) {
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    contentfulProduct(slug: { eq: $slug }) {
      title
      slug
      cover {
        file {
          url
        }
        title
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      plan {
        title
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      banner {
        title
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bannerText {
        bannerText
      }
      description {
        description
      }
      priceMinimal
      firstTextColumnMinimal {
        json
      }
      secondTextColumnMinimal {
        json
      }
      thirdTextColumnMinimal {
        json
      }
      gallery {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      icons {
        title
        description
        fluid(maxWidth: 50) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      seoTitle
      seoDescription
    }
  }
`;
